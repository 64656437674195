<template>
  <div>
    <open-banking-content />
  </div>
</template>
<script>
import OpenBankingContent from "../components/open_banking/OpenBankingContent";

export default {
  components: {
    OpenBankingContent
  }
}
</script>
<style scoped>

</style>
